import React from 'react';
import './App.css';
import 'semantic-ui-css/semantic.min.css'
import { Divider, Icon, Item, Grid, Header } from 'semantic-ui-react'

function App() {
  return (
    <div className={'App-header'}>
      <Header as='h2' icon textAlign='center' inverted>
        <Header.Content>quintsntial</Header.Content>
        {/*<Header.Subheader>(work in progress)</Header.Subheader>*/}
      </Header>
      <Divider/>
      <Grid columns={3} divided>
        <Grid.Row>
          <Grid.Column>
            <Item href='https://twitter.com/quintsntial' >
              <Icon link name='twitter' inverted/>
            </Item>
          </Grid.Column>
          <Grid.Column>
            <Item href='https://github.com/quintsntial'>
              <Icon link name='github' inverted/>
            </Item>
          </Grid.Column>
          <Grid.Column>
            <Item href='https://www.linkedin.com/in/qhenning'>
              <Icon link name='linkedin' inverted/>
            </Item>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </div>
  );
}

export default App;
